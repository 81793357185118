/**
 * This script will be inlined in index.html.
 * See HtmlInlineScriptPlugin in webpack setup
 */
let timeoutId;

/**
 * Handle errors when the webpack bundle could not be loaded by the browser.
 * @param error
 */
const handleBundleLoadingError = () => {
  document
    .getElementById('bundleLoadingErrorContainer')
    .removeAttribute('style');
  if (!timeoutId) {
    // if we could not load the bundle, we might have network issues
    // or we run into a known issue-during deployment, let's
    // try to reload
    timeoutId = window.setTimeout(() => {
      window.location.reload();
    }, 2000);
  }
};

// expose this a global function so we can use it in the onerror attribute
// of script tags
window.handleBundleLoadingError = handleBundleLoadingError;
